<template>
  <v-layout wrap>
    <v-flex xs8>
    <v-card id="main-seats-map">
      <v-responsive :aspect-ratio="16/9">
        <v-card-text style="padding: 0;">
          <canvas 
            ref="canvas" 
            style="background-color: #CCCCCC80"
          />
            {{mainWidth}}
        </v-card-text>
      </v-responsive>
    </v-card>
    </v-flex>
    <v-flex xs4>
    </v-flex>
  </v-layout>
</template>
<script>
import { fabric } from 'fabric'
import smService from '@/services/seatsMap'
export default {
  data: () => ({
    mainCanvas: null,
    mainWidth: 100,
    mainHeight: 100,
  }),
  computed: {
    canvas () {
      return this.$refs.canvas
    },
  },
  created () {
    this.mainCanvas = new fabric.Canvas(this.canvas)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.prepareSize()
    this.test()
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize () {
      this.prepareSize ()
    },
    prepareSize () {
      //const canvas = this.$refs.canvas
      const w = document.getElementById('main-seats-map').offsetWidth
      const { width, height } = smService.prepareMainSize(w)
      this.canvas.width = width
      this.canvas.height = height
      //this.mainWidth = width
      //this.mainHeight = height
      console.log(this.canvas)
    },
    test () {
      const rect = new fabric.Rect({
        fill: 'red',
        width: 200,
        height: 200
      })
      this.mainCanvas.add(rect)
    },
  },
}
</script>

