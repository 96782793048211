const cSize = {
  w: 1920,
  h: 1080,
}
const service = {
  prepareMainSize (v) {
    let width = v
    let height = v * cSize.h / cSize.w
    return { width, height }
  },
}

export default service

